export default {
	data: {
		selectedImageIndex: undefined
	},
	beforeMount: function () {
		//console.warn("ha", window.location.search);
		var url = new URL(window.location.href);
		var params = {};
		for (let key of url.searchParams.keys()) {
			//console.log("p", key, url.searchParams.get(key));
			params[key] = url.searchParams.get(key);

		}
		this.searchParams = params;
	},
	computed: {

		mailto: function () {
			return `mailto:${this.emailAddress}`;
		},
		mailto2: function () {
			return `mailto:${this.emailAddress2}`;
		},
		emailAddress: function () {
			return (VOFFICE.contactEmail || ['info', 'test.de']).join('@');
		},
		emailAddress2: function () {
			return (VOFFICE.contactEmail2 || ['info', 'test.de']).join('@');
		}

	},

	methods: {

		getSecondImageUrl: function (images) {
			console.log('getSecondImageUrl:', images[1]);
			return 'https://dyn.v-office.com/image/large/' + images[1].id + '.jpg';
		}
	}

};

$(document).ready(function () {
	const touch = matchMedia('(hover: none), (pointer: coarse)').matches;
	if (!touch) {
		$('.fac-hover').mouseenter(function () {
			var id = $(this).attr('id');
			var nr = id.split('_');
			var imgUrl = 'https://dyn.v-office.com/image/large/';

			$('#text_' + nr[1]).addClass('move');
			$('#image2_' + nr[1]).addClass('movepicture');


		});

		$('.fac-hover').mouseleave(function () {
			var id = $(this).attr('id');
			var nr = id.split('_');
			var imgUrl = 'https://dyn.v-office.com/image/large/';

			$('#text_' + nr[1]).removeClass('move');
			$('#image2_' + nr[1]).removeClass('movepicture');

		});
	}
});