import V from 'voUtils/V.js';

export default {
	props: {
		carouselId: String
	},

	mounted: function () {
		$("#owl-offer").owlCarousel({
			items: 2,
			loop: true,
			autoplay: false,
			dots: false,
			smartSpeed: 1000,
			nav: true,
			autoplayTimeout: 10000,
			margin: 30,
			responsive: {

				0: {
					items: 1
				},

				768: {
					items: 2
				},
				992: {
					items: 3
				}
			}
		});
	}
};